<template>
  <div id="home">
    <nav-bar class="home-nav"><div slot="center">购物街</div></nav-bar>
    <tab-control
      :titles="['流行', '新款', '精选']"
      class="tab-control"
      @tabClick="tabClick"
      ref="tabControl1"
      v-show="isTabFixed"
    />
    <scroll
      class="content"
      ref="scroll"
      :probe-type="3"
      :pull-up-load="true"
      :pull-down-refresh="false"
      :mouse-wheel="true"
      @scroll="contentScroll"
      @pullingUp="contentPullingUp"
      @pullingDown="contentpullingDown"
    >
      <home-swiper :banner="banner" @swiperImageLoad="swiperImageLoad" />
      <recommend-view :recommend="recommend" />
      <feature-view />
      <tab-control
        :titles="['流行', '新款', '精选']"
        class="tab-control2"
        @tabClick="tabClick"
        ref="tabControl2"
      />
      <goods-list :goods="showGoods" />
      <p class="morelp" v-show="morelp">
        {{ nomore ? "没有更多了" : "正在加载..." }}
      </p>
    </scroll>
    <back-top @click.native="backTopClick" v-show="BackTopIsshow" />
  </div>
</template>

<script>
import NavBar from "components/common/navbar/NavBar";
import tabControl from "components/content/tabControl/tabControl";
import GoodsList from "components/content/goods/GoodsList";
import Scroll from "components/common/scroll/Scroll";
import BackTop from "components/content/backTop/BackTop";

import HomeSwiper from "./childComps/HomeSwiper";
import RecommendView from "./childComps/RecommendView";
import FeatureView from "./childComps/FeatureView";

import { getHomeMultidata, getHomeGoods } from "network/home";
import { debounce } from "common/utils";

export default {
  name: "Home",
  components: {
    NavBar,
    HomeSwiper,
    RecommendView,
    FeatureView,
    FeatureView,
    tabControl,
    GoodsList,
    Scroll,
    BackTop,
  },
  data() {
    return {
      banner: [],
      dKeyword: [],
      keywords: [],
      recommend: [],
      goods: {
        pop: { page: 0, list: [] },
        sell: { page: 0, list: [] },
        new: { page: 0, list: [] },
      },
      currentType: "pop",
      BackTopIsshow: false,
      morelp: false,
      nomore: false,
      tabOffsetTop: 0,
      isTabFixed: false,
    };
  },
  computed: {
    showGoods() {
      return this.goods[this.currentType].list;
    },
  },
  created() {
    //获取头部区域数据
    this.getHomeMultidata();
    // 获取商品数据
    this.getHomeGoods("pop");
    this.getHomeGoods("sell");
    this.getHomeGoods("new");
  },
  mounted() {
    const refresh = debounce(this.$refs.scroll.refresh, 100);
    //监听事件总线
    this.$bus.$on("itemImageLoad", () => {
      this.$refs.scroll && refresh();
    });

    this.tabOffsetTop = this.$refs.tabControl2.$el.offsetTop;
  },
  methods: {
    /**
     * 事件监听
     */
    //点击tab导航
    tabClick(index) {
      switch (index) {
        case 0:
          this.currentType = "pop";
          break;
        case 1:
          this.currentType = "sell";
          break;
        case 2:
          this.currentType = "new";
          break;
      }
      this.$refs.tabControl1.currentIndex = index;
      this.$refs.tabControl2.currentIndex = index;
    },
    //回到顶部
    backTopClick() {
      this.$refs.scroll.scrollTo(0, 0, 500);
    },
    //监听滑动回调
    contentScroll(position) {
      // console.log(position)
      //监听BackTop（置顶）是否显示
      this.BackTopIsshow = -position.y > 1000;
      //监听tabControl是否吸顶
      this.isTabFixed = -position.y > this.tabOffsetTop;
    },
    //上拉加载更多
    contentPullingUp() {
      console.log("pullingUp");
      this.morelp = true;
      this.nomore = false;
      setTimeout(() => {
        this.getHomeGoods(this.currentType);
      }, 1200);
    },
    //下拉刷新
    contentpullingDown(){
      console.log("pullingDown");
    },
    swiperImageLoad() {
      //2.获取tabControl的offsetTop
      // 所有的组件都有$el 属性，用于获取组件中的元素
      this.tabOffsetTop = this.$refs.tabControl2.$el.offsetTop;
      // console.log(this.tabOffsetTop);
    },

    /**
     * 网络请求
     */

    //获取头部区域数据
    getHomeMultidata() {
      getHomeMultidata().then((res) => {
        // console.log(res)
        this.banner = res.data.banner.list;
        this.dKeyword = res.data.dKeyword.list;
        this.keywords = res.data.keywords.list;
        this.recommend = res.data.recommend.list;
      });
    },
    // 获取商品数据
    getHomeGoods(type) {
      getHomeGoods(type, this.goods[type].page + 1).then((res) => {
        console.log(res);
        if (res.success == true) {
          this.goods[type].list.push(...res.data.list);
          this.goods[type].page += 1;
          this.morelp = false;
          this.$refs.scroll.finishPullUp();
        } else {
          this.morelp = true;
          this.nomore = true;
        }
      });
    },
  },
  destroyed() {
    console.log("destroyed");
  },
  activated() {
    // console.log("activated");
    //刷新scroll
    this.$refs.scroll.refresh();
    //回到离开时位置
    this.saveY && this.$refs.scroll.scrollTo(0, this.saveY, 1);
    // console.log( this.$refs.scroll.scroll.y);
  },
  deactivated() {
    // console.log("deactivated");
    //保存离开时位置
    this.saveY = this.$refs.scroll.scroll.y;
    // console.log( this.$refs.scroll.scroll.y);
  },
};
</script>

<style scoped>
#home {
  /* padding-top: 44px; */
  height: 100vh;
}
.home-nav {
  background-color: var(--color-tint);
  color: #fff;

  /* position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99; */
}
.tab-control {
  position: relative;
  /* top: 44px; */
  z-index: 2;
}
/* .content{
  height: calc(100% - 93px);
  margin-top: 44px;
  overflow: hidden;
} */
/* .fixed{
  position: fixed;
  left: 0px;
  right: 0px;
  top: 44px;
} */
.content {
  overflow: hidden;
  position: absolute;
  top: 44px;
  left: 0px;
  right: 0px;
  bottom: 49px;
}
.morelp {
  text-align: center;
  line-height: 30px;
}
</style>