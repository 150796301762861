<template>
  <div class="back-top">
      <img src="~assets/img/common/top.png" alt="">
  </div>
</template>

<script>
export default {
    name: 'BackTop',
    methods: {
        
    }
}
</script>

<style scoped>
.back-top{
    position: fixed;
    right: 8px;
    bottom: 57px;
}
.back-top img{
    width: 43px;
    height: 43px;
}
</style>>