import { request } from "network/request";

export function getHomeMultidata(){
    return request({
        url: '/home/multidata',
    })
}

export function getHomeGoods(type,page){
    // console.log(type)
    return request({
        url: '/home/data',
        params: {
            type,
            page
        }
    })
}