<template>
  <div class="feature">
    <a href="https:act.mogujie.com/zzlx67">
      <img src="~assets/img/home/recommend_bg.jpg" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  name: "FeatureView",
};
</script>

<style scoped>
.feature img {
  width: 100%;
}
</style>